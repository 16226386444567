<template>
	<div id="app" :style="style">
		<div class="con">
			<div class="pc pctop" :class="{navh60 : isnavS}">
				<div class="is_fixed">
					<nav class="wrapper" :class="{navh60 : isnavS}">
						<div class="logo">
							<router-link to="/">
								<img :src="require('@/assets/logo.png')" alt="">
							</router-link>
						</div>
						<router-link to="/">首页</router-link>
						<div class="strendsindex">
							<p class="hover">主题赛事</p>
							<div class="subnav">
								<router-link :to='"/competitions/"+item.id' v-for="item in trands"
									:key="item.id">{{ item.competitionName }}</router-link>
							</div>
						</div>
						<router-link to="/publish">大赛动态</router-link>
						<router-link to="/community">大赛社区</router-link>
						<div class="btn" v-if="!$store.state.userId">
							<router-link to="/login">
								<p>登录</p>
							</router-link>
							<router-link to="/reg">
								<p>注册</p>
							</router-link>
						</div>
						<div class="btn" v-else>
							<router-link to="/account?type=1"><p class="mytrends">我的赛事</p></router-link>
							<el-dropdown style="display: block;" @visible-change="visibleChange">
								<span class="el-dropdown-link">
									{{ $store.state.userName }}<i class="el-icon-arrow-down el-icon--right"
										:class="{fanzhuan: isfanzhuan}"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item><router-link to="/account">个人中心</router-link></el-dropdown-item>
									<el-dropdown-item><span @click="logout">退出登录</span></el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</nav>
				</div>
			</div>
			<!-- 移动端导航 -->
			<div class="mobiles" style="height: 60px;">
				<div class="is_fixed daohang" style="background: #05060f">
					<p class="logo">
						<router-link to="/">
							<img :src="require('@/assets/logo.png')" alt="">
						</router-link>
					</p>
					<p class="iconfont icon-gengduo" @click="open" v-show="!showMenu"></p>
					<p class="iconfont icon-guanbi" @click="close" v-show="showMenu"></p>
					<!-- 移动端菜单 -->
					<div class="menu" v-show="showMenu">
						<el-menu default-active="1" class="el-menu-vertical-demo" background-color="#151515" text-color="#BBB"
							active-text-color="#9dc3f7">
							<el-menu-item index="1" @click="showMenu=false">
								<span slot="title"><router-link to="/">首页</router-link></span>
							</el-menu-item>
							<el-submenu index="2">
								<span slot="title">主题赛事</span>
								<el-menu-item :index="'2-'+index" v-for="(item,index) in trands" :key="item.id"
									@click="go(item.id)">{{ item.competitionName }}</el-menu-item>
							</el-submenu>
							<el-menu-item index="3" @click="showMenu=false">
								<span slot="title"><router-link to="/publish">大赛动态</router-link></span>
							</el-menu-item>
							<el-menu-item index="4" @click="showMenu=false">
								<span slot="title"><router-link to="/community">大赛社区</router-link></span>
							</el-menu-item>
							<el-menu-item index="5" @click="showMenu=false" v-if="!$store.state.userId">
								<span slot="title">
									<router-link to="/login">登录</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="6" @click="showMenu=false" v-if="!$store.state.userId">
								<span slot="title">
									<router-link to="/reg">注册</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="5" @click="showMenu=false" v-if="$store.state.userId">
								<span slot="title">
									<router-link class="my" to="/account">我的赛事</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="6" @click="showMenu=false" v-if="$store.state.userId">
								<span slot="title">
									<router-link to="/account">{{ $store.state.userName }}</router-link>
								</span>
							</el-menu-item>
						</el-menu>
					</div>

				</div>
			</div>
		</div>

		
		<div class="flex1">
			<router-view :isnavS='isnavS' />
		</div>
		<!-- foot -->
		<div class="foot containter">
			<div class="left">
				<p class="tit ptit">
					<router-link to="/">
						<img :src="require('@/assets/logov.png')" alt="">
					</router-link>
					HealthAI 2025 健康智能挑战赛
				</p>
				<p class="tel">联系我们</p>
				<p class="mobile">赛事咨询：competition@pkucxpl.com</p>
				<p class="mobile">联系方式：田老师 18811394296（同微信）</p>
				<p class="inno">© 2025 HealthAI 2025 健康智能挑战赛组委会<i style="padding: 5px;">|</i><a class="inno" href="http://beian.miit.gov.cn" target="_blank">京ICP备13032365号-23</a></p> 
			</div>
			<div class="right pc">
				<p class="tit">导航</p>
				<p class="nav"><router-link to="/publish">大赛动态</router-link></p>
				<p class="nav"><router-link to="/community">大赛社区</router-link></p>
			</div>
			<div class="right pc">
				<p class="tit">主题赛事</p>
				<p class="nav hover" v-for="item in trands" :key="'mf'+item.id">
					<router-link :to='"/competitions/"+item.id'>
						{{ item.competitionName }}
					</router-link>
				</p>
			</div>
			<div class="mobiles footnav">
				<div class="right">
					<p class="tit">导航</p>
					<p class="nav"><router-link to="/publish">大赛动态</router-link></p>
					<p class="nav"><router-link to="/community">大赛社区</router-link></p>
				</div>
				<div class="right">
					<p class="tit">主题赛事</p>
					<p class="nav" v-for="item in trands" :key="'mf'+item.id">
						<router-link :to='"/competitions/"+item.id'>
							{{ item.competitionName }}
						</router-link>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		competitionList
	} from "@/api/api.js"
	export default {
		data() {
			return {
				showMenu: false,
				style: {},
				pages: {
					page: 1,
					limit: 20
				},
				trands: [],
				isfanzhuan: false,
				isnavS: false
			}
		},
		watch: {
			showMenu(val) {
				if (!val) {
					this.style = {}
				}
			}
		},
		mounted() {
			this.competitionList()
			window.addEventListener('scroll', this.scls, true)
		},
		destroyed() {
			//页面销毁前关闭监听
			window.removeEventListener('scroll', this.scls, true)
		},
		methods: {
			// 滚动导航高度变化
			scls() {
				console.log()
				if(window.scrollY>5) {
					this.isnavS = true
				}else {
					this.isnavS = false
				}
			},
			go(id) {
				this.showMenu = false
				this.$router.push(`/competitions/${id}`)
			},
			open() {
				this.showMenu = true
				this.style = {
					height: '100vh',
					overflow: 'hidden'
				}
			},
			close() {
				this.showMenu = false
				this.style = {}
			},
			// 主题赛事列表
			competitionList() {
				competitionList(this.pages).then(res => {
					console.log('主题赛事列表', res.data)
					this.trands = res.data
				})
			},
			// 退出登录
			logout() {
				this.$store.commit('LOGOUT', '')
			},
			// 个人中心移入移出 
			visibleChange(e) {
				console.log(e)
				this.isfanzhuan = e
			}
		}
	}
</script>

<style lang="scss">	
	pre{
		white-space: pre-wrap!important;
		word-wrap: break-word!important;
		*white-space:normal!important;
	}
	.el-popup-parent--hidden {
		//padding-right: 0 !important;
	}
	
	// .el-dialog__wrapper::-webkit-scrollbar,
	.xiyihtml::-webkit-scrollbar
	{
	    width: 0;
	}
	
	// .el-dialog__wrapper::-moz-scrollbar,
	.xiyihtml::-moz-scrollbar
	 {
	    width: 0;
	}
	
	// .el-dialog__wrapper::-o-scrollbar,
	.xiyihtml::-o-scrollbar
	 {
	    width: 0;
	}
	
	// .el-dialog__wrapper::-ms-scrollbar,
	.xiyihtml::-ms-scrollbar
	 {
	    width: 0;
	}
	
	// .el-dialog__wrapper scrollbar,
	.xiyihtml scrollbar
	 {
	    width: 0;
	}
	
</style>
<style lang="scss">
	@import url("//at.alicdn.com/t/c/font_4211492_s2b8ex2ihe.css");
	
	@keyframes dialog-fade-in {
		0% {
				transform: translate3d(0, 0, 0);
				opacity: 1;
		}
		100% {
				transform: translate3d(0, 0, 0);
				opacity: 1;
		}
	}
	
	@keyframes dialog-fade-out {
		0% {
				transform: translate3d(0, 0, 0);
				opacity: 0;
		}
		100% {
				transform: translate3d(0, 0, 0);
				opacity: 0;
		}
	}
	
	.ql-editor,
	.el-input__inner {
		color: #fff !important;
	}
	
	
	html {
		width: 100%;
		box-sizing: border-box !important;
	}
	
	a {
		text-decoration: none;
		cursor: pointer;
	}
	
	.el-icon-close {
		cursor: pointer;
	}
	
	.el-tooltip {
		margin-right: 10px;
		margin-bottom: 10px;
	}

	#app {
		font-family: SourceHanSansSC-regular, Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #fff;
		background: #11141f; //url('~@/assets/bgnewindex.png') center center repeat
		background-size: cover;
		// background-color: #151515;
		// background: #05060f linear-gradient(0deg,rgba(216,236,248,.06),rgba(152,192,239,.06));
		// background: #05060f linear-gradient(0deg,rgba(216,236,248,.06),rgba(152,192,239,.06));
		min-height: 100.5vh;
		display: flex;
		flex-direction: column;
	}
	
	.nav-container {
		left: 0;
	}
	
	.flex1 {
		flex: 1;
	}

	p {
		margin: 0;
		padding: 0;
	}

	nav a.router-link-exact-active:hover,
	a:hover,
	.hover:hover {
		color: #9dc3f7 !important;
	}

	// .con {
	// 	border-bottom: 1px solid rgba(38, 38, 38, 1);
	// }
	
	.navh60 {
		height: 60px !important;
	}

	.left_nav {
		position: fixed;
		// top: 180px;
		top: 160px;
	}

	.left_nav1 {
		position: fixed;
	}

	.left_nav2 {
		position: fixed;
		// top: 30px;
	}

	.indexfixed {
		position: fixed;
		top: 60px;
		// background: #151515;
		background: #05060f linear-gradient(0deg,rgba(216,236,248,.06),rgba(152,192,239,.06));
		z-index: 121;
		width: 100%;
	}

	.is_fixed {
		position: fixed;
		top: 0;
		width: 100%;
		// background: #151515;
		z-index: 1000;
	}
	
	.navh60 .is_fixed {
		background: #05060f linear-gradient(0deg,rgba(216,236,248,.06),rgba(152,192,239,.06));
	}

	.hei {
		height: 195px;
		top:90px;
	}

	.pctop {
		height: 90px;
	}

	.el-dropdown {
		height: 34px;
	}

	.el-dropdown-link {
		display: block;
		width: 100%;
		height: 100%;
	}

	nav {
		height: 90px;
		display: flex;
		align-items: center;
		transition-property: all;
		transition-duration: .25s;
		transition-timing-function: cubic-bezier(.4,0,.2,1);

		.logo {
			color: #fff;
			font-family: 'AlibabaPuHui';
			font-weight: bold;
			margin-right: 20px;

			img {
				height: 40px;
			}
			a{
				padding: 0;
			}
		}

		a {
			cursor: pointer;
			color: #A3A3A3;
			font-size: 16px;
			padding: 0 28px;

			&.router-link-exact-active {
				color: #fff;
			}
		}

		.btn {
			margin-left: auto;
			color: #A3A3A3;
			display: flex;

			a {
				padding: 0;
			}

			p {
				cursor: pointer;
				width: 50px;
				font-size: 16px;
				text-align: right;
			}

			p.mytrends {
				background: #015ea1;
				color: #eee;
				padding: 0 16px;
				height: 34px;
				line-height: 34px;
				text-align: center;
				width: auto;

				a {
					color: #fff;
				}

				a:hover {
					color: #fff !important;
				}
			}

			.el-dropdown {
				line-height: 34px;
				background: none;
				border: 1px solid #2f3132;
				margin-left: 10px;
				text-align: center;
				color: #eee;
				width: 90px;
			}

			.fanzhuan {
				transform: rotate(180deg)
			}

		}
	}

	.strendsindex {
		position: relative;

		p {
			color: #A3A3A3;
			font-size: 16px;
			padding: 0 28px;
			cursor: pointer;
		}

		.subnav {
			position: absolute;
			background: #11141f;
			padding: 14px;
			color: #A3A3A3;
			min-width: 300px;
			border-top: 15px solid #11141f;
			display: none;
			z-index: 101;

			a {
				line-height: 1.4;
				padding: 4px 0;
				display: block;
				overflow: hidden;
			}
		}
	}

	.strendsindex:hover {
		.subnav {
			display: block;
		}
	}

	.wrapper {
		width: auto;
		margin: 0 60px;
	}
	.containter {
		width: 1200px;
		margin: 0 auto;
	}

	.foot {
		display: flex;
		padding-top: 256px;
		padding-bottom: 36px;

		.left {
			flex: 1;

			.tit {
				display: flex;
				align-items: center;

				img {
					height: 30px;
					margin-right: 5px;
				}
			}
			
			.ptit {
				font-size: 14px;
			}

			.tel {
				font-size: 14px;
				color: #737373;
				margin-top: 20px;
				line-height: 2;
			}

			.mobile {
				font-size: 14px;
				line-height: 2;
				color: #eee;
			}

			.inno {
				color: #737373;
				font-size: 14px;
				padding: 15px 0 60px;
			}
			
			.inno-1 {
				color: #fff;
				font-size: 14px;
				padding: 15px 0 60px;
			}
		}

		.right {
			width: 220px;

			.tit {
				font-size: 16px;
				color: #737373;
				margin-bottom: 20px;
			}

			.nav {
				font-size: 15px;
				color: #eee;
				line-height: 2;
				cursor: pointer;
				height: auto;
				display: block;
				text-align: left;
			}
		}

		.right:nth-child(2) {
			margin-left: 25px;
		}
	}

	.el-submenu.is-active .el-submenu__title {
		border-bottom: none !important;
	}
	
	.pointer {
		cursor: pointer;
	}
	
	img {
		max-width: 100%;
	}

	.pc {
		display: block !important;
	}

	.mobiles {
		display: none !important;
	}


	@media screen and (max-width: 750px) {
		.pc {
			display: none !important;
		}

		.mobiles {
			display: block !important;
		}
		
		.dialog-footer {
			div {
				width: 100%;
				display: flex;
				justify-content: end;
			}
		}

		.containter {
			width: calc(100% - 30px);
			margin: 0 15px;
			box-sizing: border-box;
		}

		.daohang {
			height: 60px;
			display: flex !important;
			align-items: center;
			justify-content: space-between;
			padding: 0 15px;
			box-sizing: border-box;
			border-bottom: 1px solid #262626;

			.logo {
				img {
					height: 30px;
				}
			}

			.iconfont {
				font-size: 20px;
			}
		}

		.foot {
			flex-direction: column-reverse;
			padding-top: 8rem;
			padding-bottom: 64px;

			.footnav {
				display: flex !important;
				margin-bottom: 30px;

				.right {
					flex: 1;
					margin-left: 0;

					.nav {
						font-size: 0.75rem;
						padding: 0;
					}

					.tit {
						margin-bottom: 0.5rem;
					}
				}
			}

			.left {
				padding-bottom: 30px;

				.tit {
					display: flex;
					align-items: center;
				}
				
				.ptit {
					font-size: 0.75rem;
				}

				.tel {
					font-size: 0.75rem;
					margin-top: 0.25rem;
				}

				.mobile {
					font-size: 0.75rem;
				}

				.inno {
					color: #737373;
					font-size: 0.75rem;
					padding: 0.25rem 0;
				}
			}
		}

		.menu {
			position: fixed;
			right: 0;
			top: 60px;
			height: calc(100vh - 60px);
			z-index: 120;
			// background: #151515;
			background: #05060f linear-gradient(0deg,rgba(216,236,248,.06),rgba(152,192,239,.06));
			width: 100%;
			overflow-y: scroll;
		}

		.el-menu {
			min-height: calc(100vh - 61px) !important;
			border-right: none !important;

		}

		.el-submenu {
			.el-menu {
				min-height: auto !important;
			}
		}

		.el-submenu__title,
		.el-menu-item {
			border-bottom: 1px solid #404040;
			padding: 0;
			margin: 0 20px;
		}

		

		.my {
			color: #1097f0;
		}
	}
</style>

<style lang="scss">
	a {
		color: #eee;
	}

	.el-dropdown-menu {
		background-color: #404040 !important;
		width: 120px;


		a {
			color: #fff;
		}
	}

	.el-dropdown-menu__item {
		color: #fff !important;
		text-align: center !important;
	}

	.el-popper .popper__arrow {
		display: none;
	}

	.el-popper {
		margin-top: 0;
	}

	.el-popper[x-placement^=bottom] .popper__arrow::after {
		border: none !important;
	}

	.el-dropdown-menu__item:focus,
	.el-dropdown-menu__item:not(.is-disabled):hover {
		background: none !important;
	}

	.el-dropdown {
		cursor: pointer;
	}
</style>
<style type="text/css">
	strong,
	b {
		font-weight: bold !important;
	}
</style>